import {priceAndCurrencyToString} from '@/utils/platform/priceAndCurrencyToString';

const PricePassTotalIndicator = ({
  price,
  currency,
}: {
  price: number;
  currency: 'ETH' | 'USD';
}) => {
  const amtText = priceAndCurrencyToString(price, currency);
  return (
    <div className="w-full flex justify-between">
      <text className="text-gray-400 text-sm">Total:</text>
      <text className="text-blue-400 text-sm">{amtText}</text>
    </div>
  );
};

export default PricePassTotalIndicator;
