import Head from 'next/head';
import {PropsWithChildren} from 'react';
import cx from 'classnames';

interface BasePageV2Props {
  title: string;
  metaDescription: string;
  className?: string;
  thumbnailImgSrc?: string;
}

const BasePageV2 = ({
  className,
  children,
  metaDescription,
  title,
  thumbnailImgSrc,
}: PropsWithChildren<BasePageV2Props>) => {
  return (
    <div
      className={cx(
        'flex flex-auto flex-col overflow-hidden font-rules text-white',
        className
      )}
    >
      <Head>
        <title>{title}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content={title} key="title" />
        <meta
          property="og:description"
          content={metaDescription}
          key="og-description"
        />
        {thumbnailImgSrc ? (
          <meta property="og:image" content={thumbnailImgSrc} key="og-image" />
        ) : null}
      </Head>

      {children}
    </div>
  );
};

export default BasePageV2;
