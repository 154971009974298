import {useRef, useLayoutEffect} from 'react';

const isBrowser = typeof window !== `undefined`;

function getScrollPosition({
  element,
  useWindow,
}: {
  element?: React.RefObject<HTMLElement>;
  useWindow?: boolean;
}) {
  if (!isBrowser) return {x: 0, y: 0};

  if (useWindow) {
    return {x: window.scrollX, y: window.scrollY};
  }

  const target = element ? element.current : document.body;

  if (!target) return {x: 0, y: 0};

  const position = target.getBoundingClientRect();

  return {x: position.left, y: position.top};
}

type Position = {x: number; y: number};

export function useScrollPosition(
  effect: ({prevPos, currPos}: {prevPos: Position; currPos: Position}) => void,
  deps: any[],
  element: React.RefObject<HTMLElement> | undefined,
  useWindow: boolean,
  wait: number
) {
  const position = useRef(getScrollPosition({useWindow}));

  let throttleTimeout: NodeJS.Timeout | null = null;

  const callBack = () => {
    const currPos = getScrollPosition({element, useWindow});
    effect({prevPos: position.current, currPos});
    position.current = currPos;
    throttleTimeout = null;
  };

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout === null) {
          throttleTimeout = setTimeout(callBack, wait);
        }
      } else {
        callBack();
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, deps);
}
