import {MinusIcon, PlusIcon} from '@heroicons/react/20/solid';

const SelectNumPassesInput = ({
  numPasses,
  setNumPasses,
}: {
  numPasses: number | null;
  setNumPasses: (numPasses: number | null) => void;
}) => {
  return (
    <div className="items-center bg-gray-600 bg-opacity-30 rounded w-full p-4 flex justify-between text-sm">
      <div className="text-gray-400 text-sm">Qty</div>
      <div className="flex space-x-2 items-center">
        <button
          disabled={numPasses == null || numPasses <= 1}
          onClick={() => {
            if (numPasses) {
              setNumPasses(numPasses - 1);
            }
          }}
        >
          <MinusIcon
            style={{color: '#F2D4D4'}}
            className="w-6 h-6 p-1 rounded bg-gray-600"
          />
        </button>
        <input
          className="max-w-[80px] w-fit text-center bg-gray-600 bg-opacity-30 flex text-2xl  p-2 rounded"
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          value={numPasses || ''}
          onChange={(e) => {
            if (e.target.value) {
              setNumPasses(parseInt(e.target.value));
            } else {
              setNumPasses(null);
            }
          }}
          onBlur={(e) => {
            if (e.target.value === '') {
              setNumPasses(1);
            }
          }}
        />
        <button
          disabled={numPasses == null}
          onClick={() => {
            if (numPasses) {
              setNumPasses(numPasses + 1);
            }
          }}
        >
          <PlusIcon className="w-6 h-6 p-1 rounded bg-gray-600" />
        </button>
      </div>
    </div>
  );
};

export default SelectNumPassesInput;
