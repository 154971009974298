import {StripeProductInfo} from '@/components/stripe/types/StripeProductTypes';

export const PLATFORM_POSTS_COLLECTION = 'platform-posts';
export const PLATFORM_PROJECTS_COLLECTION = 'platform-projects';
export const PLATFORM_TEMP_TOKENS_COLLECTION = 'platform-temp-tokens';
export const PLATFORM_HOLDINGS_COLLECTION = 'platform-holdings';
export const PLATFORM_USERS_COLLECTION = 'platform-users';
export const PLATFORM_VIDEO_COLLECTION = 'platform-video';
export const PLATFORM_VIDEO_COMMENTS_COLLECTION = 'platform-video-comments';

export const DEMO_PRODUCT: StripeProductInfo = {
  category: 'pass',
  name: 'Producer Pass',
  price: 5,
  quantity: 1,
};
