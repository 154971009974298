import ButtonV2 from '@/components/redesign/ButtonV2';

const StripePassCheckoutSuccess = ({
  handleClose,
}: {
  handleClose: () => void;
}) => {
  return (
    <div className="px-9 flex flex-col gap-8 items-center">
      <div className="font-bold">Thanks for supporting!</div>
      <div>You can now make your choice.</div>
      <ButtonV2 text="Close" onClick={handleClose} />
    </div>
  );
};

export default StripePassCheckoutSuccess;
