import {StripeProductInfo} from '@/components/stripe/types/StripeProductTypes';
import {ArrowLeftIcon} from '@heroicons/react/20/solid';

const ProductInfoPaymentBox = ({
  product,
  handleBack,
}: {
  product: StripeProductInfo;
  handleBack: () => void;
}) => {
  return (
    <div className="flex flex-col gap-y-4 items-center">
      <div className="flex flex-col gap-y-2 items-center">
        <div className="font-bold">{product.name}</div>
        <div>
          {product.quantity} at ${product.price} USD
        </div>
        <div>Total: ${product.price * product.quantity}</div>
      </div>
    </div>
  );
};

export default ProductInfoPaymentBox;
