import request from 'axios';
import {usePrivy} from '@privy-io/react-auth';
import React from 'react';

export const useRequestWithAuth = () => {
  const {getAccessToken, user} = usePrivy();
  const [authToken, setAuthToken] = React.useState<string | null>(null);
  const [authTokenReady, setAuthTokenReady] = React.useState(false);

  React.useEffect(() => {
    const init = async () => {
      if (getAccessToken && user) {
        const authToken = await getAccessToken();
        setAuthToken(authToken);
        setAuthTokenReady(true);
      }
    };

    init();
  }, [getAccessToken, user]);

  return {
    authToken,
    postRequest: <T>({path, payload}: {path: string; payload: T}) =>
      request.post(path, payload, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }),
    getRequest: <T>({path, params}: {path: string; params: T}) => {
      return request.get(path, {
        params,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
    },
    authTokenReady,
  };
};
