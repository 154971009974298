import request from 'axios';

type GetTempSessionTokenParams = {
  email: string;
  quantity: number;
  clientSecret: string;
};

const getTempSessionToken = async ({
  email,
  quantity,
  clientSecret,
}: GetTempSessionTokenParams) => {
  try {
    const resp = await request.post('/api/producer-pass/new-session-token', {
      email,
      quantity,
      clientSecret,
    });
    return resp?.data?.token;
  } catch (err) {
    console.error('Error creating temp session token:', err);
  }
};

export default getTempSessionToken;
