import SocialLinks from '@/components/redesign/SocialLinks';

const AboutTheProject = () => {
  return (
    <div className="w-full flex flex-col space-y-32">
      <div className="flex flex-col space-y-12">
        <div className="flex flex-col gap-4 col-span-2">
          <h2 className="shib-h2 text-grayscale-300">
            Mirai wakes up in a strange world without any memories...
          </h2>
          <div className="text-start text-grayscale-400 flex w-full flex-col gap-4 opacity-100 duration-500 ease-in group-hover:opacity-100">
            White Rabbit is an interactive series about the journey of going
            down the crypto rabbit hole. It follows Mirai, who embarks on a path
            to discover the meaning of free will and self-sovereignty.
          </div>
        </div>
        <div className="flex flex-col gap-4 col-span-1">
          <h2 className="shib-subtitle text-grayscale-300">
            About the project
          </h2>
          <div className="text-grayscale-400 flex w-full flex-col gap-2 opacity-100 duration-500 ease-in group-hover:opacity-100">
            <div>
              <span className="font-bold text-grayscale-300">Genres:</span>{' '}
              Sci-Fi
            </div>
            <div>
              <span className="font-bold text-grayscale-300">Directors:</span>{' '}
              Maciej Kuciara and pplpleasr
            </div>
            <div>
              <span className="font-bold text-grayscale-300">Start date:</span>{' '}
              March 2022
            </div>
          </div>
          <SocialLinks />
        </div>
      </div>
    </div>
  );
};

export default AboutTheProject;
