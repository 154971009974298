import React, {ReactElement} from 'react';

import {
  DiscordIcon,
  InstagramLogo,
  TwitterIcon,
} from '@/components/SocialIcons';
import ExternalLink from '@/components/ExternalLink';

const SOCIAL_LINKS = [
  {
    href: 'https://twitter.com/shibuyafilm',
    icon: <TwitterIcon className="h-5 w-5" />,
  },
  {
    href: 'https://discord.gg/shibuyafilm',
    icon: <DiscordIcon className="h-5 w-5" />,
  },
  {
    href: 'https://www.instagram.com/shibuya.film/',
    icon: <InstagramLogo className="h-5 w-5" />,
  },
];

const SocialLinks = ({
  links = SOCIAL_LINKS,
}: {
  links?: {href: string; icon: ReactElement}[];
}) => {
  return (
    <div className="flex items-end gap-4 text-right text-sm text-white">
      {links.map(({href, icon}) => (
        <ExternalLink
          className="font-bold transition-colors hover:text-white"
          href={href}
          key={href}
        >
          {icon}
        </ExternalLink>
      ))}
    </div>
  );
};

export default SocialLinks;
