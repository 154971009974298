import {useState} from 'react';
import {
  LinkAuthenticationElement,
  PaymentElement,
} from '@stripe/react-stripe-js';
import {StripeLinkAuthenticationElementChangeEvent} from '@stripe/stripe-js';

import useStripeCheckout from '@/components/stripe/useStripeCheckout';
import ProductInfoPaymentBox from '@/components/stripe/ProductInfoPaymentBox';
import ButtonV2 from '@/components/redesign/ButtonV2';
import {StripeProductInfo} from '@/components/stripe/types/StripeProductTypes';
import {StripePassCheckoutStage} from '@/components/stripe/types/StripeCheckoutStage';
import {DEMO_PRODUCT} from '@/utils/platform/constants';
import {BackButton} from '../profile/BackButton';
import {ArrowLeftIcon, BackwardIcon} from '@heroicons/react/20/solid';

const StripePassCheckoutPayment = ({
  handleClose,
  handleBack,
  numPasses,
  setStage,
}: {
  handleClose: () => void;
  handleBack: () => void;
  numPasses: number;
  setStage: (stage: StripePassCheckoutStage) => void;
}) => {
  const {handlePayment, paymentPending} = useStripeCheckout();
  const [email, setEmail] = useState('');

  const onPaymentSuccess = () => {
    setStage('success');
  };
  const handleLinkAuthenticationElemChange = (
    event: StripeLinkAuthenticationElementChangeEvent
  ) => {
    setEmail(event.value.email);
  };

  return (
    <div id="checkout" className="px-9 flex flex-col gap-8 items-center">
      <div className="absolute top-4 left-4">
        <button onClick={handleBack}>
          <ArrowLeftIcon className="w-5 h-5" />
        </button>
      </div>
      <ProductInfoPaymentBox
        product={{...DEMO_PRODUCT, quantity: numPasses}}
        handleBack={handleBack}
      />
      <LinkAuthenticationElement
        onChange={handleLinkAuthenticationElemChange}
      />
      <PaymentElement />

      <ButtonV2
        category="secondary"
        text="Purchase"
        disabled={paymentPending}
        onClick={() => {
          handlePayment({
            email: email,
            onSuccess: onPaymentSuccess,
          });
        }}
      />
    </div>
  );
};

export default StripePassCheckoutPayment;
