import {InteractivePlayer} from '@/components/player-v2/InteractivePlayer';
import {InteractiveScene} from '@/components/player-v2/InteractiveVoting/types';

type ShowPageVideoPlayerProps = {
  projectId: string;
  videoUrl: string;
  isOpen: boolean;
  interactions?: {[ts: number]: InteractiveScene};
  close: () => void;
};

const ShowPageVideoPlayer = ({
  projectId,
  videoUrl,
  interactions,
  isOpen,
  close,
}: ShowPageVideoPlayerProps) => {
  return isOpen ? (
    <div className="absolute top-0 w-screen h-screen bg-black">
      <InteractivePlayer
        url={videoUrl}
        backButtonBehavior={{
          type: 'click',
          onClick: close,
        }}
        interactionConfig={{
          supportedInteractions: ['scene'],
          interactiveScenes: interactions,
        }}
        isMuted={false} // Initial mute
        controls={{
          hasControls: true,
        }}
        shouldLoop={false}
        metadata={{
          projectId: projectId,
        }}
        overlayType="eden"
      />
    </div>
  ) : null;
};

export default ShowPageVideoPlayer;
