import {Elements} from '@stripe/react-stripe-js';
import {StripeElementsOptions, loadStripe} from '@stripe/stripe-js';
import {ReactNode, createContext, useContext, useState} from 'react';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY as string
);

const StripePassCheckoutContext = createContext<{
  numPasses: number | null;
  setNumPasses: (numPasses: number | null) => void;
}>({
  numPasses: 1,
  setNumPasses: () => {},
});

export const useStripeCheckoutContext = () => {
  const context = useContext(StripePassCheckoutContext);
  if (!context) {
    throw new Error(
      'usePlayerVolume must be used within a PlayerVolumeProvider'
    );
  }
  return context;
};

const StripePassCheckoutProvider = ({children}: {children: ReactNode}) => {
  const [numPasses, setNumPasses] = useState<number | null>(1);

  const STRIPE_ELEMENT_OPTIONS: StripeElementsOptions = {
    appearance: {
      theme: 'night',
    },
    mode: 'payment',
    currency: 'usd',
    amount: 500 * (numPasses || 1),
  };

  return (
    <StripePassCheckoutContext.Provider value={{numPasses, setNumPasses}}>
      <Elements stripe={stripePromise} options={STRIPE_ELEMENT_OPTIONS}>
        {children}
      </Elements>
    </StripePassCheckoutContext.Provider>
  );
};

export default StripePassCheckoutProvider;
