import {useScrollWithDuration} from '@/utils/hooks';
import {useScrollPosition} from '@/utils/hooks/useScrollPosition';
import {useMediaQuery} from '@react-hook/media-query';
import {useEffect, useRef, useState} from 'react';

import BasePageV2 from '@/components/redesign/BasePageV2';
import ButtonV2 from '@/components/redesign/ButtonV2';
import NavBarV2 from '@/components/redesign/NavBarV2';
import AboutTheProject from '@/components/show-page/AboutTheProject';
import {Container} from '@/components/Container';
import {DynamicImage} from '@/components/DynamicImage';
import {StaticImageData} from 'next/image';
import {PassCheckoutBox} from '@/components/white-rabbit/about/PassCheckoutBox';
import PlatformShowPagePost from '@/components/show-page/PlatformShowPagePost';

type ProjectMetadata = {
  title: string;
  description: string;
  logo: StaticImageData;
  trailerUrl: string;
};

const EXAMPLE_JSON = {
  type: 'doc',
  content: [
    {
      type: 'heading',
      attrs: {
        level: 2,
      },
      content: [
        {
          type: 'text',
          text: 'Heading 2',
        },
      ],
    },
    {
      type: 'paragraph',
      content: [
        {
          type: 'text',
          text: 'Example ',
        },
        {
          type: 'text',
          marks: [
            {
              type: 'bold',
            },
          ],
          text: 'Text',
        },
        {
          type: 'text',
          text: ' with a bolded word.',
        },
      ],
    },
    {
      type: 'paragraph',
      content: [
        {
          type: 'text',
          text: 'Italicized text',
        },
        {
          type: 'text',
          marks: [
            {
              type: 'italic',
            },
          ],
          text: ' here.',
        },
      ],
    },
    {
      type: 'paragraph',
      content: [
        {
          type: 'text',
          text: `The darkness drops again; but now I know
          That twenty centuries of stony sleep
          Were vexed to nightmare by a rocking cradle,
          And what rough beast, its hour come round at last,
          Slouches towards Bethlehem to be born? `,
        },
      ],
    },
  ],
};

const DEMO_POSTS = [
  {
    title: 'Post 1',
    requiredPasses: 1,
    preview: 'Lorem ipsum...',
    content: EXAMPLE_JSON,
  },
  {
    title: 'Post 2',
    image: 'abc',
    preview: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit...',
    requiredPasses: 3,
  },
  {
    title: 'Post 3',
    preview: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit...',
    requiredPasses: 3,
  },
  {
    title: 'Post 4',
    image: 'abc',
    requiredPasses: 0,
    preview: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit...',
  },
];
/* Show page for a non-Shibuya owned project on our platform */
const PlatformShowPage = ({
  projectMetadata,
  isVideoOpen,
  openVideoPlayer,
  children,
}: {
  projectMetadata: ProjectMetadata;
  isVideoOpen: boolean;
  openVideoPlayer: () => void;
  children?: React.ReactNode;
}) => {
  const {ref: sectionRef, scrollTo: scrollToSection} = useScrollWithDuration();
  const [scrolledDown, setScrolledDown] = useState(false);
  const isMobile = useMediaQuery('only screen and (max-width: 768px)');
  const trailerRef = useRef<HTMLVideoElement>(null);

  useScrollPosition(
    ({prevPos, currPos}) => {
      if (prevPos.y < 200 && currPos.y >= 200) {
        setScrolledDown(true);
      }
    },
    [scrolledDown],
    undefined,
    true,
    0
  );

  useEffect(() => {
    if (isVideoOpen) {
      trailerRef.current?.pause();
    } else {
      trailerRef.current?.play();
    }
  }, [isVideoOpen]);

  return (
    <BasePageV2
      className="overflow-auto flex flex-col"
      title={projectMetadata.title}
      metaDescription={projectMetadata.description}
    >
      <div className="absolute top-0">
        <video
          ref={trailerRef}
          autoPlay
          muted
          className={'h-screen w-screen object-cover'}
        >
          <source src={projectMetadata.trailerUrl} type="video/mp4" />
        </video>
      </div>

      {children}

      {!isVideoOpen ? (
        <>
          <div className="justify-between w-screen flex flex-col h-screen pb-16 sm:pb-30">
            <NavBarV2 className="relative z-10 bg-transparent" />
            <Container className="flex flex-col items-center sm:items-start">
              <div className="w-full mb-6 sm:mb-9 max-w-[380px]">
                <DynamicImage
                  layout="responsive"
                  alt={`${projectMetadata.title} logo`}
                  src={projectMetadata.logo}
                />
              </div>
              <div className="flex flex-col sm:flex-row gap-4">
                <ButtonV2
                  onClick={openVideoPlayer}
                  text={'Play show'}
                  category="secondary-translucent"
                  size="lg"
                  className="w-full"
                />
              </div>
            </Container>
          </div>
          <div className="w-screen p-16 flex flex-col gap-16">
            <AboutTheProject />
          </div>
          {/* <div className="w-screen p-16 gap-16 bg-gray-950 grid grid-cols-4">
            <div className="col-span-3 flex flex-col gap-4">
              <div className="shib-subtitle">Creator updates</div>
              <div className="grid grid-cols-2 gap-4">
                {DEMO_POSTS.map((post) => (
                  <PlatformShowPagePost
                    post={post}
                    key={`${post.title} ${post.preview.slice(0, 20)}`}
                  />
                ))}
              </div>
            </div>
            <div className="p-2 bg-gray-900 col-span-1">
              <PassCheckoutBox />
            </div>
          </div> */}
        </>
      ) : null}
    </BasePageV2>
  );
};

export default PlatformShowPage;
