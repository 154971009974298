// Page that wraps a React component page and requires authentication.

import {ReactNode, useEffect, useState} from 'react';
import {usePrivy} from '@privy-io/react-auth';

import ButtonV2 from '@/components/redesign/ButtonV2';
import {IS_PRODUCTION_ENV} from '@/utils/env';
import {useRequestWithAuth} from '@/utils/auth/requestWithAuth';

type Rule = 'loggedIn' | 'dev' | 'prod_whitelist';

type PageWithAuthProps = {
  children: ReactNode;
  rule: Rule;
  wlId?: string;
};

type Validation = 'allowed' | 'denied' | 'loading' | null;

const PageWithAuth = ({children, rule, wlId}: PageWithAuthProps) => {
  const {user, ready, login} = usePrivy();

  // For Whitelist rule only
  const [userValidated, setUserValidated] = useState<Validation>(
    rule === 'prod_whitelist' ? 'loading' : null
  );

  const {getRequest, authTokenReady} = useRequestWithAuth();

  useEffect(() => {
    if (rule !== 'prod_whitelist') {
      return;
    }

    if (!ready) {
      return;
    }

    const validate = async () => {
      if (wlId) {
        const resp = await getRequest({
          path: '/api/whitelist',
          params: {wlId: wlId},
        });
        setUserValidated(resp.data.allowed ? 'allowed' : 'denied');
      }
      return false;
    };

    if (!IS_PRODUCTION_ENV) {
      setUserValidated('allowed');
      return;
    }

    if (user) {
      validate();
    } else {
      setUserValidated('denied');
    }
  }, [user, authTokenReady]);

  if (!ready || userValidated === 'loading') {
    return <div></div>;
  }
  switch (rule) {
    case 'prod_whitelist':
      if (!IS_PRODUCTION_ENV) {
        return <>{children}</>;
      }
      if (!user) {
        return (
          <div className="flex h-screen w-screen items-center justify-center">
            <div>
              <ButtonV2
                onClick={login}
                category={'translucent'}
                size={'sm'}
                text="Login"
              />
            </div>
          </div>
        );
      } else if (userValidated === 'denied') {
        return (
          <div className="flex h-screen w-screen items-center justify-center">
            <div>
              <h1 className="text-3xl font-bold">Page not found</h1>
            </div>
          </div>
        );
      } else {
        return <>{children}</>;
      }
    case 'dev':
      if (IS_PRODUCTION_ENV) {
        return (
          <div className="flex h-screen w-screen items-center justify-center">
            <div>
              <h1 className="text-3xl font-bold">Page not found</h1>
            </div>
          </div>
        );
      } else {
        return <>{children}</>;
      }
    case 'loggedIn':
      if (!user) {
        return (
          <div className="flex h-screen w-screen items-center justify-center">
            <div>
              <ButtonV2
                onClick={login}
                category={'translucent'}
                size={'sm'}
                text="Login"
              />
            </div>
          </div>
        );
      } else {
        return <>{children}</>;
      }
  }
};

export default PageWithAuth;
