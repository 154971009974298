import {useState} from 'react';
import request from 'axios';
import ShowPageVideoPlayer from '@/components/show-page/ShowPageVideoPlayer';
import StripePassCheckoutModal from '@/components/stripe/StripePassCheckoutModal';
import StripePassCheckoutProvider from '@/components/stripe/StripePassCheckoutProvider';
import {InteractiveScene} from '@/components/player-v2/InteractiveVoting/types';
import PageWithAuth from '@/components/auth/PageWithAuth';
import WhiteRabbitLogo from 'public/images/white-rabbit-credits-logo.png';
import PlatformShowPage from '@/components/show-page/PlatformShowPage';
import {useTempSession} from '@/utils/auth/TempSessionProvider';

const ProjectPage = () => {
  const {sessionToken, isTempSessionValid} = useTempSession();

  const canUserChooseOptions = async () => {
    // TODO: Check if a Privy user has a pass
    if (!isTempSessionValid) {
      return false;
    }

    const res = await request.get('/api/platform/can-choose-options', {
      headers: sessionToken
        ? {
            'shib-temp-auth': 'Temp ' + sessionToken,
          }
        : {},
    });
    return res?.data?.canChooseOptions as boolean;
  };

  // TODO: Grab the project metadata from the project doc in Firestore
  const INTERACTIVE_SCENE_EP_1: {[ts: number]: InteractiveScene} = {
    10: {
      timestamp: 10,
      name: 'Episode 1',
      videoUrl:
        'https://stream.mux.com/7WyMoOetfGct4EdfD006qS6U718ZoSbApS1nj82afjsg.m3u8',
      popup: {
        type: 'image',
        imageUrl: '/images/white-rabbit/ch-5/pick-door-text-animated.gif',
        x: 'center',
        y: 0.15,
        w: 361,
        h: 103,
      },
      unskippable: true,
      handleExit: (e) => {
        e.preventDefault();
        setIsVideoPlayerOpen(false);
      },
      options: {
        1: {
          id: 1,
          key: 'left',
          text: 'Take the door to the left',
          choiceOverlayVideoUrl:
            'https://storage.googleapis.com/shibuya-white-rabbit/ch5/WR_CH1_FOX.mp4',
          cutsceneVideoUrl:
            'https://stream.mux.com/887VzFSjLY1Ko02BAmPsRg00efSKtOXKi4ypXFk5qYZ3M.m3u8',
          getIsEligibleToSelect: canUserChooseOptions,
          onSelectIneligible: () => {
            openStripePassCheckout();
          },
        },
        2: {
          id: 2,
          key: 'right',
          text: 'Take the door to the right',
          choiceOverlayVideoUrl:
            'https://storage.googleapis.com/shibuya-white-rabbit/ch5/WR_CH1_LADY.mp4',
          cutsceneVideoUrl:
            'https://stream.mux.com/niE7ghDN95BvOTMISZmu0101eDIFt01Zu3voMYer5fWnPU.m3u8',
          getIsEligibleToSelect: canUserChooseOptions,
          onSelectIneligible: () => {
            openStripePassCheckout();
          },
        },
      },
    },
  };
  const TEST_PROJECT_METADATA = {
    projectId: 'test',
    title: 'Test project',
    description: 'Test description',
    logo: WhiteRabbitLogo,
    trailerUrl:
      'https://storage.googleapis.com/shibuya-white-rabbit/white_rabbit_show_page_hero.mp4',
    mainVideoUrl:
      'https://stream.mux.com/fltPavk01z02Nzqnq2UMl2TGAlrL6J6WzoyCS3ZqUBcyE.m3u8',
    interactions: INTERACTIVE_SCENE_EP_1,
  };

  const [isVideoPlayerOpen, setIsVideoPlayerOpen] = useState(false);
  const [isStripePassCheckoutOpen, setIsStripePassCheckoutOpen] =
    useState(false);

  const openVideoPlayer = () => {
    setIsVideoPlayerOpen(true);
  };

  const closeVideoPlayer = () => {
    setIsVideoPlayerOpen(false);
  };

  const openStripePassCheckout = () => {
    setIsStripePassCheckoutOpen(true);
  };

  const closeStripePassCheckout = () => {
    setIsStripePassCheckoutOpen(false);
  };

  return (
    <PageWithAuth rule="dev">
      <PlatformShowPage
        projectMetadata={TEST_PROJECT_METADATA}
        isVideoOpen={isVideoPlayerOpen}
        openVideoPlayer={openVideoPlayer}
      >
        <ShowPageVideoPlayer
          isOpen={isVideoPlayerOpen}
          close={closeVideoPlayer}
          projectId={TEST_PROJECT_METADATA.projectId}
          videoUrl={TEST_PROJECT_METADATA.mainVideoUrl}
          interactions={TEST_PROJECT_METADATA.interactions}
        />
        <StripePassCheckoutProvider>
          <StripePassCheckoutModal
            visible={isStripePassCheckoutOpen}
            handleClose={closeStripePassCheckout}
          />
        </StripePassCheckoutProvider>
      </PlatformShowPage>
    </PageWithAuth>
  );
};

export default ProjectPage;
