import EdenModal from '@/components/modals/EdenModal';
import {useState} from 'react';
import StripePassCheckoutPayment from '@/components/stripe/StripePassCheckoutPayment';
import {StripePassCheckoutStage} from '@/components/stripe/types/StripeCheckoutStage';
import StripePassCheckoutSelectQuantity from '@/components/stripe/StripePassCheckoutSelectQuantity';
import {useStripeCheckoutContext} from '@/components/stripe/StripePassCheckoutProvider';
import StripePassCheckoutSuccess from '@/components/stripe/StripePassCheckoutSuccess';

const StripePassCheckoutModal = ({
  visible,
  handleClose,
}: {
  visible: boolean;
  handleClose: () => void;
}) => {
  const [stage, setStage] = useState<StripePassCheckoutStage>('quantity');
  const {numPasses, setNumPasses} = useStripeCheckoutContext();

  const handleOpenChange = (visible: boolean) => {
    if (!visible) {
      handleClose();
    }
  };

  let content;

  if (stage === 'payment') {
    content = (
      <StripePassCheckoutPayment
        handleClose={handleClose}
        handleBack={() => setStage('quantity')}
        numPasses={numPasses || 1}
        setStage={setStage}
      />
    );
  } else if (stage === 'quantity') {
    content = (
      <StripePassCheckoutSelectQuantity
        numPasses={numPasses}
        setNumPasses={setNumPasses}
        setStage={setStage}
      />
    );
  } else if (stage == 'success') {
    content = <StripePassCheckoutSuccess handleClose={handleClose} />;
  }

  return (
    <EdenModal
      openState={{
        open: visible,
        setOpen: handleOpenChange,
      }}
    >
      {content}
    </EdenModal>
  );
};

export default StripePassCheckoutModal;
