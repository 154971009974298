import * as Dialog from '@radix-ui/react-dialog';

type EdenModalOpenState = {
  open: boolean;
  setOpen: (open: boolean) => void;
};
type EdenModalProps = {
  title?: string;
  children: React.ReactNode;
  openState?: EdenModalOpenState;
};

const EdenModal = ({title, openState, children}: EdenModalProps) => {
  const openStateProps = openState
    ? {
        open: openState.open,
        onOpenChange: openState.setOpen,
      }
    : {};

  return (
    <Dialog.Root {...openStateProps}>
      <Dialog.Portal>
        <Dialog.Content
          onPointerDownOutside={(event) => {
            openState?.setOpen(false);
          }}
          className="data-[state=open]:animate-contentShow
         fixed top-[50%] left-[50%] max-h-[85vh]
         max-w-[850px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-gray-700 backdrop-blur-lg
         bg-opacity-80 p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] 
         focus:outline-none z-50"
        >
          {title && <Dialog.Title>{title}</Dialog.Title>}
          {children}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default EdenModal;
