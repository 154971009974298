import request from 'axios';

const createPaymentIntent = async (email: string) => {
  // Create a Checkout Session
  try {
    const resp = await request.post('/api/producer-pass/payment-intent', {
      quantity: 1, // TODO: change this to be dynamic
      email: email,
    });
    return {
      clientSecret: resp?.data?.clientSecret,
    };
    // TODO: Save resp.data.paymentIntentId to local storage to recover
    // in case the session is interrupted
  } catch (err) {
    console.error('Error creating payment intent:', err);
  }
};

export default createPaymentIntent;
