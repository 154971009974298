import {useElements, useStripe} from '@stripe/react-stripe-js';
import {useState} from 'react';
import createPaymentIntent from '@/utils/stripe/createPaymentIntent';
import getTempSessionToken from '@/utils/stripe/getTempSessionToken';
import {useTempSessionActions} from '@/utils/auth/TempSessionProvider';

type HandlePaymentOptions = {
  email: string;
  onSuccess?: () => void;
};

function useStripeCheckout() {
  const stripe = useStripe();
  const elements = useElements();
  const {updateSessionToken} = useTempSessionActions();
  const [loading, setLoading] = useState(false);

  const handlePayment = async ({email, onSuccess}: HandlePaymentOptions) => {
    if (!stripe || !elements) {
      console.error('Stripe.js has not loaded yet');
      return;
    }
    setLoading(true);

    try {
      // Trigger form validation and wallet collection
      const {error: submitError} = await elements.submit();
      if (submitError) {
        // TODO: Handle error and potentially retry
        console.error('Error submitting payment:', submitError);
        setLoading(false);
        return;
      }
      // Create payment intent
      const intent = await createPaymentIntent(email);
      const clientSecret = intent?.clientSecret;

      const {error: paymentError} = await stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url: window.location.href,
        },
        redirect: 'if_required',
      });
      if (paymentError) {
        console.error('Error confirming payment:', paymentError);
        // TODO: Handle payment error and retry
      } else {
        // Tell Shibuya server that we paid, so we can get a session token
        const jwt = await getTempSessionToken({
          email,
          quantity: 1, // TODO: Change this to be dynamic
          clientSecret,
        });

        // Save the token to local storage
        if (jwt) {
          updateSessionToken(jwt);
        }
        onSuccess?.();
      }
    } catch (error) {
      console.error('Error confirming payment:', error);
    }

    setLoading(false);
  };

  return {handlePayment, paymentPending: loading};
}

export default useStripeCheckout;
