import {StripePassCheckoutStage} from '@/components/stripe/types/StripeCheckoutStage';
import SelectNumPassesInput from '@/components/stripe/SelectNumPassesInput';
import PricePassTotalIndicator from '@/components/stripe/PricePassTotalIndicator';
import {DEMO_PRODUCT} from '@/utils/platform/constants';
import {PricePassIndicator} from '@/components/stripe/PricePassIndicator';
import ButtonV2 from '@/components/redesign/ButtonV2';
import {InformationCircleIcon} from '@heroicons/react/24/outline';
import Tooltip from 'rc-tooltip';

const StripePassCheckoutSelectQuantity = ({
  setStage,
  numPasses,
  setNumPasses,
}: {
  setStage: (stage: StripePassCheckoutStage) => void;
  numPasses: number | null;
  setNumPasses: (numPasses: number | null) => void;
}) => {
  return (
    <div className="px-9 flex flex-col gap-8 items-center max-w-xs">
      <div className="flex flex-col gap-4">
        <div className="text-center">Buy a pass to continue.</div>

        {/* <PricePassIndicator price={DEMO_PRODUCT.price} currency="USD" /> */}
        <div className="text-xs text-center">
          Proceeds help fund the next episode and unlock exclusive content.
          <Tooltip
            placement="right"
            trigger={['hover']}
            overlay={
              <div className="text-xs text-gray-100 flex flex-col">
                Reasons to buy
              </div>
            }
          >
            <InformationCircleIcon className="w-3 h-3 inline-block ml-1 text-gray-100" />
          </Tooltip>
        </div>
      </div>
      <SelectNumPassesInput numPasses={numPasses} setNumPasses={setNumPasses} />
      <PricePassTotalIndicator
        price={DEMO_PRODUCT.price * (numPasses || 1)}
        currency="USD"
      />
      <PassesSoldProgressBar
        loading={false}
        passesSold={600}
        totalPasses={1000}
      />
      <ButtonV2 text="Become a producer" onClick={() => setStage('payment')} />
    </div>
  );
};

export const PassesSoldProgressBar = ({
  loading,
  passesSold,
  totalPasses,
}: {
  loading: boolean;
  passesSold: number | null;
  totalPasses: number | null;
}) => {
  if (loading || passesSold == null || totalPasses == null) {
    return (
      <div className="flex flex-col space-y-2">
        <div className="text-gray-400 text-sm">Loading...</div>
        <div className="relative w-full h-4 rounded bg-gray-500">
          <div
            className={`bg-blue-600 h-4 absolute rounded`}
            style={{width: `0%`}}
          />
        </div>
      </div>
    );
  }

  const fillBar = (passesSold / totalPasses) * 100;

  return (
    <div className="flex flex-col space-y-2 w-full items-center">
      <div className="text-gray-400 text-sm">
        {totalPasses - passesSold} / {totalPasses} passes left to goal
      </div>
      <div className="relative w-full h-4 rounded bg-gray-500">
        <div
          className={`bg-cyan-600 h-4 absolute rounded`}
          style={{width: `${fillBar}%`}}
        />
      </div>
    </div>
  );
};

export default StripePassCheckoutSelectQuantity;
